import React from "react"
import { graphql } from "gatsby"
import tocEntries from "../../../lib/tocEntries"
import TableOfContents from "./TableOfContents"
import Title from "../../basics/title"
import Footer from "../../basics/footer"
import CourseHeader from "../../basics/courseHeader"
import CourseHero from "../../basics/courseHero"
import Advertisement from "../../basics/advertisement"

const Course = ({ data, pageContext }) => {
  // console.log("data: ", data);
  const {
    currentPage: {
      frontmatter: {
        coverImagePath,
        heroDescription,
        themeColor,
        title,
        toc,
        overview,
        learningPath,
        resources,
      },
      // fields: { course },
    },
    pages,
    // site: { siteMetada },
  } = data
  // const { slug } = pageContext.slug
  const entries = toc && toc.length > 0 ? tocEntries(toc, pages) : []

  return (
    <>
      <CourseHeader themeColor={themeColor} />
      <CourseHero
        themeColor={themeColor}
        title={title}
        imagePath={coverImagePath}
        description={heroDescription}
        ctaHref={entries[0].slug}
      />
      <div className="pt-16 px-16">
        <main>
          <div className="flex flex-wrap -mx-16">
            <div className="w-full md:w-1/2 px-6 sm:px-16">
              <div className="mb-4">
                <Title>Overview</Title>
                <p>{overview}</p>
              </div>
              {entries.length > 0 && <TableOfContents entries={entries} />}
            </div>
            <div className="w-full md:w-1/2 px-6 sm:px-16">
              <h2 className="title">What you'll learn</h2>
              <ul className="grid grid-cols-2 col-gap-4 list-check">
                {learningPath.map((topic, index) => (
                  <li key={index}>{topic}</li>
                ))}
              </ul>
              <Advertisement />
              <div className="my-4">
                {(resources.syllabus.tubit || resources.syllabus.tubsccsit) && (
                  <p className="graytitle tracking-widest uppercase">
                    Resources
                  </p>
                )}
                {(resources.syllabus.tubit || resources.syllabus.tubsccsit) && (
                  <p className="font-bold pl-4">Syllabus:</p>
                )}

                <div className="pl-8">
                  {resources.syllabus.tubsccsit && (
                    <p>
                      <a
                        href={resources.syllabus.tubsccsit}
                        className="test-sm text-blue-600 underline"
                        download
                      >
                        Download for TU BSCCSIT
                      </a>
                    </p>
                  )}
                  {resources.syllabus.tubit && (
                    <p>
                      <a
                        href={resources.syllabus.tubit}
                        className="test-sm text-blue-600 underline"
                        download
                      >
                        Download for TU BIT
                      </a>
                    </p>
                  )}
                </div>
              </div>
            </div>
          </div>
        </main>
        <Footer />
      </div>
    </>
  )
}

export default Course

export const query = graphql`
  query CourseQuery($slug: String!, $course: String!) {
    site {
      siteMetadata {
        title
      }
    }
    currentPage: markdownRemark(fields: { slug: { eq: $slug } }) {
      frontmatter {
        coverImagePath
        description
        heroDescription
        title
        themeColor
        toc
        overview
        learningPath
        resources {
          syllabus {
            tubit
            tubsccsit
          }
        }
      }
      fields {
        course
      }
    }
    pages: allMarkdownRemark(
      filter: {
        fields: { course: { eq: $course }, pageType: { eq: "chapter" } }
      }
    ) {
      edges {
        node {
          frontmatter {
            title
            tocTitle
            description
          }
          fields {
            slug
            chapter
          }
        }
      }
    }
  }
`
