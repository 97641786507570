import React from "react"
import { Link } from "gatsby"
import Subtitle from "../../basics/subtitle"

const TableOfConentents = ({ entries }) => (
  <>
    <Subtitle>Table of Contents</Subtitle>

    <ol className="mb-10">
      {entries.map((entry, index) => (
        <li
          key={entry.slug}
          className="border mb-2 rounded hover:border-blue-300"
        >
          <Link to={entry.slug}>
            <div className="flex items-center space-x-6 p-4">
              <div>
                <span className="font-extrabold text-xl text-gray-600">
                  {index + 1}
                </span>
              </div>
              <div>
                <p className="font-bold">{entry.tocTitle || entry.title}</p>
                <p className="text-sm">{entry.description}</p>
              </div>
            </div>
          </Link>
        </li>
      ))}
    </ol>
  </>
)

export default TableOfConentents
