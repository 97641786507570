import React from "react"
import { Link } from "gatsby"
import CourseTitle from "./courseTitle"

const CourseHero = ({ themeColor, title, description, ctaHref, imagePath }) => {
  const buttonColor = themeColor.replace(/text/, "bg").replace(/bg/, "text")

  return (
    <div className={`flex flex-row flex-wrap items-center py-12 ${themeColor}`}>
      <div className="w-full md:w-1/2 flex-grow-0 px-6 md:px-16 text-center sm:text-left">
        <CourseTitle>{title}</CourseTitle>

        {description && <p className="py-6 leading-loose">{description}</p>}

        <div className="py-6">
          <Link
            to={ctaHref}
            className={`px-8 py-3 rounded-full font-extrabold hover:shadow-xl ${buttonColor}`}
          >
            Lets Start
          </Link>
        </div>
      </div>
      <div className="w-full md:w-1/2 px-16">
        <img
          src={imagePath}
          alt={`cover for ${title} course`}
          className="w-full object-cover"
        />
      </div>
    </div>
  )
}

export default CourseHero
